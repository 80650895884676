<template>
  <base-card v-if="b_SelectedUser">
    <v-alert
      dense
      text
      type="success"
    >
      User selected
    </v-alert>
  </base-card>
  <base-card v-else>
    <div>
      <v-row>
        <v-col md="12">
          <transition-group
            appear
            name="fade-up"
            tag="div"
          >
            <v-data-iterator
              key
              :items="filteredItems"
              :items-per-page.sync="itemsPerPage"
              :search="s_UserSearch"
              hide-default-footer
              :page.sync="page"
              :sort-by="sortBy.toLowerCase()"
              :sort-desc="sortDesc"
            >
              <template v-slot:header>
                <v-card
                  color="grey lighten-4"
                  flat
                >
                  <v-toolbar
                    dark
                    color="blue darken-3"
                    class="mb-1 ml-3 mr-3"
                  >
                    <v-text-field
                      v-model="s_UserSearch"
                      clearable
                      flat
                      solo-inverted
                      hide-details
                      prepend-inner-icon="mdi-magnify"
                      label="Search"
                    />
                    <template v-if="$vuetify.breakpoint.mdAndUp">
                      <v-spacer />
                      <v-select
                        v-model="sortBy"
                        flat
                        solo-inverted
                        hide-details
                        :items="keys"
                        prepend-inner-icon="mdi-magnify"
                        label="Sort by"
                        class="mr-1"
                      />
                      <v-btn-toggle
                        v-model="sortDesc"
                        mandatory
                      >
                        <v-btn
                          large
                          depressed
                          color="blue"
                          :value="false"
                        >
                          <v-icon>mdi-arrow-up</v-icon>
                        </v-btn>
                        <v-btn
                          large
                          depressed
                          color="blue"
                          :value="true"
                        >
                          <v-icon>mdi-arrow-down</v-icon>
                        </v-btn>
                      </v-btn-toggle>
                      <v-spacer />
                      <v-select
                        v-model="s_RoleFilter"
                        flat
                        solo-inverted
                        hide-details
                        :items="a_Roles"
                        label="Role"
                      />
                    </template>
                  </v-toolbar>
                </v-card>
              </template>
              <template v-slot:default="props">
                <v-row>
                  <v-col
                    v-for="(item, index) in props.items"
                    :key="index"
                    cols="12"
                    sm="6"
                    md="6"
                    lg="4"
                  >
                    <list-card-two
                      :avatar="item.img"
                      :title="item.name"
                      :sub-title="item.role"
                      primary-btn="View Profile"
                      secondary-btn="PROFILE"
                      @click-primary-btn="getProfile($event, item.i_PersonID)"
                    />
                  </v-col>
                </v-row>
              </template>
              <template v-slot:footer>
                <v-row
                  class="mt-2 mr-4"
                  align="center"
                  justify="center"
                >
                  <v-spacer />

                  <span
                    class="mr-4 grey--text"
                  >Page {{ page }} of
                    {{ numberOfPages }}</span>
                  <v-btn
                    small
                    fab
                    dark
                    color="blue darken-3"
                    class="mr-1"
                    @click="formerPage"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn
                    small
                    fab
                    dark
                    color="blue darken-3"
                    class="ml-1"
                    @click="nextPage"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </template>
            </v-data-iterator>
          </transition-group>
        </v-col>
      </v-row>
    </div>
  </base-card>
</template>
<script>
  import ListcardTwo from '@/components/card/listCard/ListCardTwo'
  import { get } from '../../../worker/worker-api'
  import { mapActions } from 'vuex'

  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      name: 'ListColumn Two',
    },
    components: {
      'list-card-two': ListcardTwo,
    },
    data () {
      return {
        b_SelectedUser: false,
        s_UserSearch: '',
        itemsPerPageArray: [10, 15, 18],
        s_RoleFilter: 'All',
        a_Roles: ['All', 'Student', 'Teacher', 'Parent', 'Company'],
        sortDesc: false,
        page: 1,
        itemsPerPage: 15,
        sortBy: 'name',
        keys: [
          'Name',
          'Role',
        ],
        items: [
          {
            img: require('@/assets/images/avatars/001-man.svg'),
            name: 'Timothy clarkson',
            role: 'Student',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/002-woman.svg'),
            name: 'Zac Clarken',
            role: 'Student',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/003-man-1.svg'),
            name: 'Zac Clarkson',
            role: 'Teacher',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/007-woman-2.svg'),
            name: 'Bimothy clarkson',
            role: 'Teacher',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/006-woman-1.svg'),
            name: 'Pac Clarkson',
            role: 'Student',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/003-man-1.svg'),
            name: 'Tac Clarkson',
            role: 'Parent',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/faces/1.jpg'),
            name: 'Rimothy clarkson',
            role: 'Teacher',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/faces/2.jpg'),
            name: 'Nac Clarkson',
            role: 'Student',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/faces/3.jpg'),
            name: 'Dac Clarkson',
            role: 'Student',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/001-man.svg'),
            name: 'Mimothy clarkson',
            role: 'Teacher',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/002-woman.svg'),
            name: 'Dac Clarkson',
            role: 'Student',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/003-man-1.svg'),
            name: 'Rac Clarkson',
            role: 'Student',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/007-woman-2.svg'),
            name: 'Mimothy clarkson',
            role: 'Parent',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/006-woman-1.svg'),
            name: 'Tac Clarkson',
            role: 'Student',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/003-man-1.svg'),
            name: 'Zac Clarkson',
            role: 'Student',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/001-man.svg'),
            name: 'Dimothy clarkson',
            role: 'Teacher',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/002-woman.svg'),
            name: 'Bac Clarkson',
            role: 'Student',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/003-man-1.svg'),
            name: 'Cac Clarkson',
            role: 'Student',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/007-woman-2.svg'),
            name: 'Simothy clarkson',
            role: 'Teacher',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/006-woman-1.svg'),
            name: 'Pac Clarkson',
            role: 'Student',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/003-man-1.svg'),
            name: 'Zac Clarkson',
            role: 'Parent',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/faces/1.jpg'),
            name: 'Rimothy clarkson',
            role: 'Teacher',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/faces/2.jpg'),
            name: 'Zac Clarkson',
            role: 'Parent',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/003-man-1.svg'),
            name: 'Mac Clarkson',
            role: 'Parent',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/faces/1.jpg'),
            name: 'Timothy clarkson',
            role: 'Teacher',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/faces/2.jpg'),
            name: 'Lac Clarkson',
            role: 'Student',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/faces/3.jpg'),
            name: 'Zac Clarkson',
            role: 'Student',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/001-man.svg'),
            name: 'Bimothy clarkson',
            role: 'Teacher',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/002-woman.svg'),
            name: 'Zac Clarkson',
            role: 'Company',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/003-man-1.svg'),
            name: 'Rac Clarkson',
            role: 'Student',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/007-woman-2.svg'),
            name: 'Timothy clarkson',
            role: 'Teacher',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/006-woman-1.svg'),
            name: 'Rac Clarkson',
            role: 'Company',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/003-man-1.svg'),
            name: 'Zac Clarkson',
            role: 'Student',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/001-man.svg'),
            name: 'Mimothy clarkson',
            role: 'Company',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/002-woman.svg'),
            name: 'Lac Clarkson',
            role: 'Student',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/003-man-1.svg'),
            name: 'Tac Clarkson',
            role: 'Student',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/007-woman-2.svg'),
            name: 'Cimothy clarkson',
            role: 'Parent',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/006-woman-1.svg'),
            name: 'Mac Clarkson',
            role: 'Student',
            i_PersonID: 48829,
          },
          {
            img: require('@/assets/images/avatars/003-man-1.svg'),
            name: 'Bac Clarkson',
            role: 'Student',
            i_PersonID: 48829,
          },
        ],
      }
    },
    computed: {
      numberOfPages () {
        return Math.ceil(this.items.length / this.itemsPerPage)
      },
      filteredKeys () {
        return this.keys.filter((key) => key !== 'Name')
      },
      filteredItems () {
        if (this.s_RoleFilter === 'All') return this.items
        return this.items.filter((item) => item.role === this.s_RoleFilter)
      },
    },
    methods: {
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      updateItemsPerPage (number) {
        this.itemsPerPage = number
      },
      getProfile (event, Ai_PersonID) {
        get(`${process.env.VUE_APP_SMT_API_URL}/user/${Ai_PersonID}`, {})
          .then(response => {
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.setRoles(response.a_Roles);
            if(response.a_Roles.length > 0) this.setCurrentRole(response.a_Roles[0]);
            this.setPlanningPeriods(response.a_PlanningPeriods);
            let Lo_CurrentPlanningPeriod = this.$smt.findCurrentPeriod(response.a_PlanningPeriods, response.a_Roles[0].s_Role);
            this.setCurrentPlanningPeriod(Lo_CurrentPlanningPeriod);
            this.setProfile(response);
            this.b_SelectedUser = true;
          })
          .catch(error => {
          })
      },
      ...mapActions(['setRoles', 'setProfile', 'setPlanningPeriods', 'setCurrentRole', 'signOut']),
    },
  }
</script>
<style lang="scss" scoped>
.fade-up-enter {
    opacity: 0;
    transform: translateY(40px);
}
.fade-up-enter-active {
    transition: all 0.4s ease;
}

.page-wrap {
    background-color: #242939 !important;
    display: flex;
    align-items: center;
    padding: 10px 1rem;
    height: 100%;
    min-height: 100vh;
}
.session-form-hold {
    width: 100%;
    margin: 0 auto;
}
</style>
